import { Grid, Container, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';
import { NumbersCount } from './NumbersCount';
import { NumbersTitle } from './NumbersTitle';

// const StyledSection = styled('section')(({ theme }) => ({
//   padding: [[35, 0, 38, 0]],
//   backgroundColor: '#DAFFF3',

//   [theme.breakpoints.down('sm')]: {
//     padding: [[10, 0]],
//     overflow: 'hidden',
//     position: 'relative',
//   },

//   '& ul': {
//     display: 'flex',
//     flexWrap: 'wrap',
//     padding: '0px',

//     '& li': {
//       display: 'block',
//       width: '25%',
//       textAlign: 'center',
//       padding: '0px',
//       borderRight: ' 1px solid #7EDBBD',

//       [theme.breakpoints.down('sm')]: {
//         width: '50%',
//         margin: [[20, 0]],
//       },

//       '& h4': {
//         fontWeight: 900,
//         fontSize: 32,
//         lineHeight: '40px',
//         color: '#111111',

//         [theme.breakpoints.down('md')]: {
//           fontSize: 30,
//         },

//         [theme.breakpoints.down('sm')]: {
//           fontSize: 28,
//         },
//       },

//       '& h5': {
//         fontSize: 16,
//         lineHeight: '24px',
//         padding: '3px 30px',

//         [theme.breakpoints.down('md')]: {
//           fontSize: 16,
//         },

//         [theme.breakpoints.down('sm')]: {
//           paddingTop: '0',
//           fontSize: 16,
//         },
//       },
//     },

//     '& li:last-child': {
//       borderRight: '0px',
//     },

//     '& li:nth-child(2)': {
//       [theme.breakpoints.down('sm')]: {
//         borderRight: '0px',
//       },
//     },
//   },
// }));

// const useStyles = makeStyles((theme) => ({
//   NumbersBg: {
//     padding: [[35, 0, 38, 0]],
//     backgroundColor: '#DAFFF3',

//     [theme.breakpoints.down('sm')]: {
//       padding: [[10, 0]],
//       overflow: 'hidden',
//       position: 'relative',
//     },

//     '& ul': {
//       display: 'flex',
//       flexWrap: 'wrap',
//       padding: '0px',

//       '& li': {
//         display: 'block',
//         width: '25%',
//         textAlign: 'center',
//         padding: '0px',
//         borderRight: ' 1px solid #7EDBBD',

//         [theme.breakpoints.down('sm')]: {
//           width: '50%',
//           margin: [[20, 0]],
//         },

//         '& h4': {
//           fontWeight: 900,
//           fontSize: 32,
//           lineHeight: '40px',
//           color: '#111111',

//           [theme.breakpoints.down('md')]: {
//             fontSize: 30,
//           },

//           [theme.breakpoints.down('sm')]: {
//             fontSize: 28,
//           },
//         },

//         '& h5': {
//           fontSize: 16,
//           lineHeight: '24px',
//           padding: '3px 30px',

//           [theme.breakpoints.down('md')]: {
//             fontSize: 16,
//           },

//           [theme.breakpoints.down('sm')]: {
//             paddingTop: '0',
//             fontSize: 16,
//           },
//         },
//       },

//       '& li:last-child': {
//         borderRight: '0px',
//       },

//       '& li:nth-child(2)': {
//         [theme.breakpoints.down('sm')]: {
//           borderRight: '0px',
//         },
//       },
//     },
//   },
// }));

const StyledSection = styled('section')(({ theme }) => ({
  padding: '35px 0 38px 0',

  backgroundColor: '#DAFFF3',

  [theme.breakpoints.down('sm')]: {
    padding: '10px 0',
    overflow: 'hidden',
    position: 'relative',
  },

  '& ul': {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px',

    '& li': {
      display: 'block',
      width: '25%',
      textAlign: 'center',
      padding: '0px',
      borderRight: ' 1.5px solid #7EDBBD',

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        margin: '20px 0',
      },

      '& h4': {
        fontWeight: 900,
        fontSize: 32,
        lineHeight: '40px',
        color: '#111111',

        [theme.breakpoints.down('md')]: {
          fontSize: 30,
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 28,
        },
      },

      '& h5': {
        fontSize: 16,
        lineHeight: '24px',
        padding: '3px 30px',

        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },

        [theme.breakpoints.down('sm')]: {
          paddingTop: '0',
          fontSize: 16,
        },
      },
    },

    '& li:last-child': {
      borderRight: '0px',
    },

    '& li:nth-child(2)': {
      [theme.breakpoints.down('sm')]: {
        borderRight: '0px',
      },
    },
  },
}));
function Numbers() {
  // const classes = useStyles();

  return (
    // <section className={classes.NumbersBg}>
    <StyledSection>
      <Container maxWidth="lg">
        <Grid container direction="row">
          <Grid item xs={12}>
            <List>
              <ListItem>
                <NumbersCount count="3,061" />
                <NumbersTitle title="New startups launched in India in 2020" />
              </ListItem>
              <ListItem>
                <NumbersCount count="$955K" />
                <NumbersTitle title="Median ticket size for seed stage funding deals in 2020" />
              </ListItem>
              <ListItem>
                <NumbersCount count="11" />
                <NumbersTitle title="New startups entered unicorn club in 2020" />
              </ListItem>
              <ListItem>
                <NumbersCount count="$11.5 Bn" />
                <NumbersTitle title="Total funding secured in 2020 across 924 deals" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
    // </section>
  );
}

export default Numbers;
