/* eslint-disable @next/next/no-img-element */
import { Container, Box } from '@mui/material';
import { LetterSpacedHeading } from 'components/common';
import Slider from 'react-slick';
import OurPartnersImgEight from 'assets/images/our-partners-img-eight.svg';
import OutPartnersImgFour from 'assets/images/our-partners-img-one.svg';
import OutPartnersImgOne from 'assets/images/our-partners-img-two.svg';
import OutPartnersImgTwo from 'assets/images/our-partners-img-three.svg';
import OutPartnersImgThree from 'assets/images/our-partners-img-four.svg';
import OutPartnersImgFive from 'assets/images/our-partners-img-five.svg';
import OutPartnersImgSix from 'assets/images/our-partners-img-six.svg';
import OutPartnersImgSeven from 'assets/images/our-partners-img-seven.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface ArrowIconType {
  currentSlide?: number;
  slideCount?: number;
}

const SlickArrowLeft = ({
  currentSlide: _currentSlide,
  slideCount: _slideCount,
  ...props
}: ArrowIconType) => (
  <ChevronLeftIcon
    {...props}
    sx={{
      zIndex: 90,
      fontSize: 50,
      color: '#111',
      '&:hover': {
        color: '#111',
      },
    }}
  />
);

const SlickArrowRight = ({
  currentSlide: _currentSlide,
  slideCount: _slideCount,
  ...props
}: ArrowIconType) => (
  <ChevronRightIcon
    {...props}
    sx={{
      zIndex: 90,
      fontSize: 50,
      color: '#111',
      '&:hover': {
        color: '#111',
      },
    }}
  />
);

const OurPartners = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container
      component="section"
      sx={{
        pt: '59px',
        pb: '54px',
        // px: '300px',
        overflow: 'hidden',
        '& .slick-track': {
          display: 'flex',
          alignItems: 'center',
          '& .slick-slide': {
            '& div': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        '.slick-prev.slick-disabled': {
          visibility: 'hidden',
        },
        '.slick-next.slick-disabled': {
          visibility: 'hidden',
        },
        '.slick-slide': {
          margin: '0 26px',
        },
      }}
    >
      <LetterSpacedHeading sx={{ mb: '43px' }} component="h2">
        OUR PARTNERS
      </LetterSpacedHeading>
      <Box sx={{ px: { xs: 0, lg: 10 } }}>
        <Slider {...settings}>
          <img src={OurPartnersImgEight.src} alt="Freshworks logo" />
          <img src={OutPartnersImgFive.src} alt="Freshworks logo" />
          <img src={OutPartnersImgThree.src} alt="Freshworks logo" />
          <img src={OutPartnersImgFour.src} alt="Freshworks logo" />
          <img src={OutPartnersImgTwo.src} alt="Freshworks logo" />
          <img src={OutPartnersImgOne.src} alt="Freshworks logo" />
          <img src={OutPartnersImgSix.src} alt="Freshworks logo" />
          <img src={OutPartnersImgSeven.src} alt="Freshworks logo" />
        </Slider>
      </Box>
    </Container>
  );
};

export default OurPartners;
