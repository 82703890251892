import { Box, Container, Grid } from '@mui/material';
import { CustomButton } from 'components/common';
import BannerContent from './BannerContent';
import { BannerTitle } from './BannerTitle';
import investorBanner from 'assets/images/banner-img-home.png';
import startupBanner from 'assets/images/banner-img-startup.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import Link from 'components/Link';

const Banner = ({ userType }: { userType: number | undefined }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(218, 255, 243, 0.34)',
      }}
    >
      <Container
        component="header"
        fixed={true}
        sx={{
          pt: '100px',
          pb: '80px',
        }}
      >
        <Grid container direction="row" spacing={2.5}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <BannerTitle
              title1={userType === 2 ? 'Raise funds' : 'Invest in'}
              title2={
                userType === 2 ? 'for your startup!' : 'Promising Startups'
              }
              fontWeight={900}
              fontSize={64}
              color="#111111"
              lineHeight="74px"
            />
            <BannerContent>
              {userType === 2
                ? `If you believe your business is going to disrupt the market,
             you're in the right place. Connect with 100+ investors on
             POD.`
                : `Here's your chance to become a leading investor in the next market disruptor. Choose from some of the best curated and vetted startups.`}
            </BannerContent>
            <Link
              href={
                userType === 1
                  ? '/startups'
                  : userType === 2
                  ? '/create-startup-profile'
                  : '/register'
              }
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  md: 'flex-start',
                },
              }}
            >
              <CustomButton
                sx={{ fontSize: 20, width: 248, height: 62 }}
                variant="contained"
                disableElevation
              >
                {userType === 2
                  ? 'Submit Pitch'
                  : userType === 1
                  ? 'Invest Now'
                  : 'Join Now'}
              </CustomButton>
            </Link>
          </Grid>
          {mdUp && (
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                '& img': {
                  maxWidth: '100%',
                },
              }}
            >
              {userType === 2 ? (
                <img src={startupBanner.src} alt="startup banner" />
              ) : (
                <img src={investorBanner.src} alt="investor banner" />
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;
