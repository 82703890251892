import { Box, Grid, GridSize, Typography } from '@mui/material';

interface HowPodWorksCardProps {
  count: number;
  title: string;
  content: string;
  gridSize: GridSize;
}

export const HowPodWorksCard = ({
  count,
  title,
  content,
  gridSize,
}: HowPodWorksCardProps) => {
  return (
    <Grid item xs={12} sm={6} lg={gridSize}>
      <Box
        sx={{
          m: 'auto',
          width: '40px',
          borderRadius: '100px',
          border: '2px solid #fff',
          py: '6px',
          px: '12px',
          fontWeight: 'bold',
          lineHeight: '24px',
          fontSize: '20px',
        }}
      >
        {count}
      </Box>
      <Typography
        fontSize={20}
        fontWeight="bold"
        paddingTop="28px"
        paddingBottom="12px"
      >
        {title}
      </Typography>
      <Typography fontSize={18} fontWeight={300} lineHeight="28px">
        {content}
      </Typography>
    </Grid>
  );
};
