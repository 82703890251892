import { Box, Container, Typography } from '@mui/material';
import { LetterSpacedHeading } from 'components/common';
import { CustomButton } from 'components/common';
import { Faq, FaqQNAType } from 'components/common';
import Link from 'components/Link';

const FaqLanding = ({ userType }: { userType: number | undefined }) => {
  const investorQuestionAndAnswer: FaqQNAType[] = [
    {
      question: 'How does POD facilitate fundraising?',
      answer:
        'POD connects promising startups with Angel investors, HNIs and VCs among others. The value-add POD brings is ensuring all startups are vetted by a world-class team. Once this clearance happens, the startups are allowed to make their business pitch to potential investors. And if the investors find potential in the startups, they can choose to invest in them in return for equity.',
    },
    {
      question: 'Who can be an investor on POD?',
      answer:
        'Any Indian citizen who is 21 years of age and above and has a significant understanding/knowledge of investment and its risk can be an investor on POD.',
    },
    {
      question: 'What is the minimum amount of investment one can start with?',
      answer: (
        <div>
          The minimum amount one can invest through POD is{' '}
          <strong>₹20,000/-.</strong> The maximum limit for Non-accredited
          investors is ₹2.5L. And for the Accredited investors, the maximum
          limit is 10% of their Net Value.
        </div>
      ),
    },
    {
      question: 'How does the entire process of fundraising work on POD?',
      answer: (
        <Box>
          Here is all you need to know about raising funds on POD
          <Typography
            component="h6"
            fontWeight={600}
            fontSize="16px"
            sx={{ textDecoration: 'underline', mt: 3 }}
          >
            Startup Onboarding
          </Typography>
          <Box component="ul" sx={{ '& li': { ml: -1.5, mb: 2 } }}>
            <li>
              <strong>Onboarding: </strong>As the first step, a startup founder
              is asked to share the relevant details about his business and
              other KYC requirements. These details are collected through a form
              and sent to the team for vetting and due diligence.
            </li>
            <li>
              <strong>Due diligence: </strong>The team verifies all the business
              details, vets KYC etc. to ensure that the information submitted by
              the startup is legitimate. Only after this will the startup be
              launched on POD.
            </li>
            <li>
              <strong>Fundraising Campaign: </strong>Once the startup clears the
              vetting process, a fundraising campaign with a specific goal
              amount is launched on POD.
            </li>
          </Box>
          <Typography
            component="h6"
            fontWeight={600}
            fontSize="16px"
            sx={{ textDecoration: 'underline', mt: 3 }}
          >
            Investor Onboarding
          </Typography>
          <Box component="ul" sx={{ '& li': { ml: -1.5, mb: 2 } }}>
            <li>
              <strong>Investor Onboarding: </strong>Once the investor finds a
              startup that&apos;s aligned with their vision and interest, they
              can go right ahead and invest with a single click. Different modes
              of payment are available for investors to choose from.
            </li>
            <li>
              <strong>Investor Assessment: </strong>Investors have to answer a
              questionnaire to verify their understanding of investment risks.
            </li>
            <li>
              <strong>KYC Verification: </strong>Once the KYC of the investor is
              successfully verified, they will be onboarded on POD and get
              access to choose from a range of startups to invest in.
            </li>
          </Box>
          <Typography
            component="h6"
            fontWeight={600}
            fontSize="16px"
            sx={{ textDecoration: 'underline', mt: 3 }}
          >
            Investment and Fund/share transfer
          </Typography>
          <Box component="ul" sx={{ '& li': { ml: -1.5, mb: 2 } }}>
            <li>
              <strong>Investments: </strong>Once the investor finds a startup
              that&apos;s aligned with their vision and interest, they can go
              right ahead and invest with a single click. Different modes of
              payment are available for investors to choose from.
            </li>
            <li>
              <strong>Campaign Status: </strong>When an investor invests in a
              startup, the funds remain in POD&apos;s Escrow account. If the
              startup reaches its funding goal, the amount is transferred to the
              startup. If not, the funds will be refunded to the investor.
            </li>
            <li>
              <strong>End of Campaign: </strong>Funds are transferred to a
              startup if it reaches its funding goal. If not, a refund is
              initiated to the investor. Post this, the campaign is marked as
              closed.
            </li>
          </Box>
        </Box>
      ),
    },
    {
      question: 'How much does POD charge for each Investment done through it?',
      answer:
        'As of now, POD levies no commission from investors on their investment made through the platform. The primary focus is to create a reliable and transparent ecosystem for startups to raise funds in India.',
    },
  ];
  const startupQuestionAndAnswer: FaqQNAType[] = [
    {
      question: 'How does POD facilitate fundraising?',
      answer:
        'POD connects promising startups with Angel investors, HNIs and VCs among others. The value-add POD brings is ensuring all startups are vetted by a world-class team. Once this clearance happens, the startups are allowed to make their business pitch to potential investors. And if the investors find potential in the startups, they can choose to invest in them in return for equity.',
    },
    {
      question: 'How does the entire process of fundraising work on POD?',
      answer: (
        <Box>
          Here is all you need to know about raising funds on POD
          <Typography
            component="h6"
            fontWeight={600}
            fontSize="16px"
            sx={{ textDecoration: 'underline', mt: 3 }}
          >
            Startup Onboarding
          </Typography>
          <Box component="ul" sx={{ '& li': { ml: -1.5, mb: 2 } }}>
            <li>
              <strong>Onboarding: </strong>As the first step, a startup founder
              is asked to share the relevant details about his business and
              other KYC requirements. These details are collected through a form
              and sent to the team for vetting and due diligence.
            </li>
            <li>
              <strong>Due diligence: </strong>The team verifies all the business
              details, vets KYC etc. to ensure that the information submitted by
              the startup is legitimate. Only after this will the startup be
              launched on POD.
            </li>
            <li>
              <strong>Fundraising Campaign: </strong>Once the startup clears the
              vetting process, a fundraising campaign with a specific goal
              amount is launched on POD.
            </li>
          </Box>
          <Typography
            component="h6"
            fontWeight={600}
            fontSize="16px"
            sx={{ textDecoration: 'underline', mt: 3 }}
          >
            Investor Onboarding
          </Typography>
          <Box component="ul" sx={{ '& li': { ml: -1.5, mb: 2 } }}>
            <li>
              <strong>Investor Onboarding: </strong>Once the investor finds a
              startup that&apos;s aligned with their vision and interest, they
              can go right ahead and invest with a single click. Different modes
              of payment are available for investors to choose from.
            </li>
            <li>
              <strong>Investor Assessment: </strong>Investors have to answer a
              questionnaire to verify their understanding of investment risks.
            </li>
            <li>
              <strong>KYC Verification: </strong>Once the KYC of the investor is
              successfully verified, they will be onboarded on POD and get
              access to choose from a range of startups to invest in.
            </li>
          </Box>
          <Typography
            component="h6"
            fontWeight={600}
            fontSize="16px"
            sx={{ textDecoration: 'underline', mt: 3 }}
          >
            Investment and Fund/share transfer
          </Typography>
          <Box component="ul" sx={{ '& li': { ml: -1.5, mb: 2 } }}>
            <li>
              <strong>Investments: </strong>Once the investor finds a startup
              that&apos;s aligned with their vision and interest, they can go
              right ahead and invest with a single click. Different modes of
              payment are available for investors to choose from.
            </li>
            <li>
              <strong>Campaign Status: </strong>When an investor invests in a
              startup, the funds remain in POD&apos;s Escrow account. If the
              startup reaches its funding goal, the amount is transferred to the
              startup. If not, the funds will be refunded to the investor.
            </li>
            <li>
              <strong>End of Campaign: </strong>Funds are transferred to a
              startup if it reaches its funding goal. If not, a refund is
              initiated to the investor. Post this, the campaign is marked as
              closed.
            </li>
          </Box>
        </Box>
      ),
    },
    {
      question:
        'What is the eligibility criteria for a startup to get registered on POD?',
      answer: (
        <Box>
          A startup should:
          <br />
          a. Be registered in India and incorporated not more than 3 years ago
          <br />
          b. Execute a unique business idea with a reasonable size target market
          <br />
          c. At least have an MVP ready
        </Box>
      ),
    },
    {
      question: 'What is the vetting process like?',
      answer:
        'The Team at POD will perform complete due diligence to ensure that the details submitted by the startup are legal, valid and meets the requirements mandated to be registered on the platform. This process is expected to be completed in 7 business days. Should a startup not clear due diligence, the team will reach out with updates on how to rectify areas of concern before they can reapply.',
    },
    {
      question:
        'What are the minimum and maximum amount of funds a startup can raise on POD?',
      answer:
        'As of now, a startup is allowed to raise funds ranging from INR 20 Lakhs to INR 1Cr. on POD.',
    },
  ];
  return (
    <Container
      sx={{
        pt: '102px',
        // pb: '80px',
        pb: {
          xs: '20px',
          md: '80px',
        },
      }}
    >
      <LetterSpacedHeading sx={{ pb: '50px' }} component="h2">
        FREQUENTLY ASKED QUESTIONS
      </LetterSpacedHeading>
      <Box
        sx={{
          // px: '35px',
          px: {
            xs: 0,
            md: '35px',
          },
        }}
      >
        {userType === 1
          ? investorQuestionAndAnswer.map((faq, index) => (
              <Faq key={index} index={index} {...faq} />
            ))
          : startupQuestionAndAnswer.map((faq, index) => (
              <Faq key={index} index={index} {...faq} />
            ))}
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Link href={`/faqs${userType === 2 ? '?userType=startup' : ''}`}>
          <CustomButton
            sx={{
              fontSize: 20,
              width: 248,
              height: 62,
              // ml: 'auto',
              // display: 'inline-block',
            }}
            variant="contained"
            disableElevation
          >
            View More FAQs
          </CustomButton>
        </Link>
      </Box>
    </Container>
  );
};

export default FaqLanding;
