import { Box, Container, Grid } from '@mui/material';
import { LetterSpacedHeading } from 'components/common';
import { CustomButton } from 'components/common';
import Link from 'components/Link';
// import { useUser } from 'context/user.context';
import { HowPodWorksCard } from './HowPodWorksCard';

const investorHowPodWorksData = [
  {
    count: 1,
    title: 'Signup',
    content:
      'Sign up and create your profile by submitting your details on the platform.',
  },
  {
    count: 2,
    title: 'Build',
    content:
      'Build your investor account adding more information, validating understanding of investments and signing the agreement.',
  },
  {
    count: 3,
    title: 'Invest',
    content:
      'Explore the best startups listed, shortlist the promising ones and finally, invest in a startup that aligns with your vision.',
  },
  {
    count: 4,
    title: 'Share Certificate',
    content:
      'Receiving a Share Certificate is assured and easy. The Share Certificate will be released once the startup reaches its campaign goal.',
  },
];

const startupHowPodWorksData = [
  {
    count: 1,
    title: 'Signup',
    content:
      'Sign up and create your profile by submitting your details on the platform.',
  },
  {
    count: 2,
    title: 'Register',
    content:
      'Post the review and screening process, shortlisted startups will be registered on POD.',
  },
  {
    count: 3,
    title: 'Raise Funds',
    content: 'Voila! Start receiving funds from interested investors.',
  },
];

const HowPodWorks = ({ userType }: { userType: number }) => {
  // const [{ user }] = useUser();
  const renderCards = () => {
    if (userType === 2) {
      return startupHowPodWorksData.map(({ ...data }) => (
        <HowPodWorksCard gridSize={4} key={data.count} {...data} />
      ));
    }
    return investorHowPodWorksData.map(({ ...data }) => (
      <HowPodWorksCard gridSize={3} key={data.count} {...data} />
    ));
  };

  return (
    <Box
      pt="84px"
      pb="70px"
      component="section"
      sx={{
        backgroundColor: '#04B078',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg">
        <LetterSpacedHeading sx={{ mt: '20px', mb: '50px' }} component="h2">
          HOW POD WORKS
        </LetterSpacedHeading>
        <Grid container rowSpacing={5} columnSpacing={5}>
          {renderCards()}
        </Grid>
        <Box pt="40px">
          <Link
            href={
              userType === 2
                ? '/startup-how-it-works'
                : '/investor-how-it-works'
            }
          >
            <CustomButton
              sx={{ fontSize: 20, width: 248, height: 62 }}
              variant="contained"
              disableElevation
            >
              Know More
            </CustomButton>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default HowPodWorks;
