import { Box, Container, Typography } from '@mui/material';
import { CustomButton, LetterSpacedHeading } from 'components/common';
import Link from 'components/Link';

const RaiseFundsWithPod = () => {
  return (
    <Box
      sx={{ backgroundColor: 'rgba(218, 255, 243, 0.5)', pb: '70px' }}
      component="section"
    >
      <LetterSpacedHeading sx={{ pb: '36px', pt: '80px' }} component="h2">
        RAISE FUNDS WITH POD
      </LetterSpacedHeading>
      <Container maxWidth="md">
        <Typography
          align="center"
          fontSize={20}
          color="rgba(17, 17, 17, 0.7)"
          // px={10}
          sx={{ maxWidth: '700px', m: 'auto' }}
        >
          Connect with top-tier Angel Investors, HNIs, VCs and more. If you
          believe your business is going to disrupt the market, you&apos;ve come
          to the right place.
        </Typography>
        <Box display="flex" justifyContent="center">
          <Link href="/create-startup-profile">
            <CustomButton
              sx={{
                fontSize: 20,
                width: 248,
                height: 62,
                mt: 4,
              }}
              variant="contained"
              disableElevation
            >
              Submit Pitch
            </CustomButton>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default RaiseFundsWithPod;
