import { Typography } from '@mui/material';
import { FC } from 'react';

export const NumbersTitle: FC<{ title: string }> = ({
  title,
  ...otherProps
}) => {
  return (
    <Typography variant="h5" {...otherProps}>
      {title}
    </Typography>
  );
};
