import { Box, Grid, GridSize, Typography } from '@mui/material';
// import Image from 'next/image';

interface WhyPodCard2Props {
  whyPodIcon: any;
  title: string;
  content: string;
  gridSize: GridSize;
}

const WhyPodCard2 = ({
  whyPodIcon,
  title,
  content,
  gridSize,
}: WhyPodCard2Props) => {
  return (
    <Grid
      item
      xs={12}
      md={gridSize}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: {
          xs: 'center',
          md: 'left',
        },
      }}
      // textAlign="center"
    >
      <Box
        sx={{
          // flexBasis: '70px',
          width: '70px',
          height: '70px',
          border: '1px solid #a7ffe3',
          borderRadius: '5px',
          // display: 'inline-block',
          // height: 70,
          display: 'flex',
          // flexDirection: {
          //   xs: 'column',
          // },
          justifyContent: 'center',
          flexShrink: 0,
          alignSelf: 'flex-start',
          // '& img': {
          //   margin: 'auto',
          // },
          margin: {
            xs: 'auto',
            md: 0,
          },
        }}
      >
        <img
          src={whyPodIcon.src}
          alt={`${title} icon`}
          // layout="fill"
          width={48}
        />
      </Box>

      <Box mt={2}>
        <Typography
          component="h3"
          variant="h3"
          fontSize={20}
          paddingBottom="24px"
          lineHeight="24px"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Typography
          color="rgba(17, 17, 17, 0.7)"
          fontWeight={300}
          fontSize="18px"
          lineHeight="28px"
          sx={{
            pr: {
              xs: 0,
              md: '35px',
            },
          }}
        >
          {content}
        </Typography>
      </Box>
    </Grid>
  );
};

export default WhyPodCard2;
