import { Box, Grid, GridSize, Typography } from '@mui/material';
// import Image from 'next/image';

interface WhyPodCardProps {
  whyPodIcon: any;
  title: string;
  content: string;
  gridSize: GridSize;
}

const WhyPodCard = ({
  whyPodIcon,
  title,
  content,
  gridSize,
}: WhyPodCardProps) => {
  return (
    <Grid
      item
      xs={12}
      md={gridSize}
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          // flexBasis: '70px',
          width: '70px',
          height: '70px',
          border: '1px solid #a7ffe3',
          borderRadius: '5px',
          // display: 'inline-block',
          // height: 70,
          display: 'flex',
          // flexDirection: {
          //   xs: 'column',
          // },
          justifyContent: 'center',
          flexShrink: 0,
          alignSelf: 'flex-start',
        }}
      >
        <img
          src={whyPodIcon.src}
          alt={`${title} icon`}
          // layout="fill"
          width={48}
        />
      </Box>

      <Box sx={{ ml: '30px' }}>
        <Typography
          component="h3"
          variant="h3"
          fontSize={20}
          paddingBottom="12px"
          lineHeight="24px"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Typography
          color="rgba(17, 17, 17, 0.7)"
          fontWeight={300}
          fontSize="18px"
          lineHeight="28px"
          sx={{
            pr: '35px',
          }}
        >
          {content}
        </Typography>
      </Box>
    </Grid>
  );
};

export default WhyPodCard;
