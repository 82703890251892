import { Box, Container, Grid } from '@mui/material';
import { LetterSpacedHeading } from 'components/common';
import whypodIcon1 from '../../../assets/images/whypod-icon1.svg';
import whypodIcon2 from '../../../assets/images/whypod-icon2.svg';
import whypodIcon3 from '../../../assets/images/whypod-icon3.svg';
import whypodIcon4 from '../../../assets/images/whypod-icon4.svg';
import whypodIcon5 from '../../../assets/images/whypod-icon5.svg';
import whypodIcon6 from '../../../assets/images/whypod-icon6.svg';
import WhyPodCard from './WhyPodCard';
import WhyPodCard2 from './WhyPodCard2';
// import { useUser } from 'context/user.context';

const investorWhyPodData = [
  {
    title: 'Investor Friendly',
    content:
      'POD is an investor-friendly platform, which levies no commission or hidden charges from investors.',
    src: whypodIcon1,
  },
  {
    title: 'Transparent and Real time',
    content:
      'The entire process is transparent as the investor can see and track the progress of the fundraising campaign in real-time.',
    src: whypodIcon2,
  },
  {
    title: 'Quality Startups',
    content:
      'POD lists only hand-picked startups that qualify for the minimum growth rate and minimum time spent in a competitive market criteria.',
    src: whypodIcon3,
  },
  {
    title: 'Safeguard Guaranteed',
    content:
      'In order to safeguard the funds invested, we guarantee a full refund in case the fundraising campaign does not reach its goal.',
    src: whypodIcon4,
  },
  {
    title: 'POD for All',
    content: `POD's vision is to democratize fundraising in India and allow every investor to participate with an investment amount as low as ₹20,000.`,
    src: whypodIcon5,
  },
  {
    title: 'All or Nothing Model',
    content:
      "If a startup doesn't reach its campaign goal, we refund the amount, allowing investors to back only the startups that have other investors as well. Thus, leading to a low-risk policy.",
    src: whypodIcon6,
  },
];

const startupWhyPodData = [
  {
    title: 'Leading Investors',
    content:
      'POD gives startup founders access to a curated list of top VCs, Angel Investors and HNIs among others. These prospective investors are constantly looking for promising startups to invest in.',
    src: whypodIcon3,
  },
  {
    title: 'Transparent',
    content:
      'Right from the time of registering on POD, everything on the platform is transparent and seamless. One can effortlessly track the progress of the campaign and plan the next steps for the business.',
    src: whypodIcon2,
  },
  {
    title: 'Safe and Secure',
    content:
      'POD has a remarkable process and a world-class platform in place that makes the entire startup fundraising journey safe and secure. Startup founders can raise funds online with complete trust and assurance.',
    src: whypodIcon4,
  },
];

const WhyPod = ({ userType }: { userType: number }) => {
  const renderCards = () => {
    if (userType === 2) {
      return startupWhyPodData.map(({ title, content, src }, i) => (
        <WhyPodCard2
          key={i}
          title={title}
          content={content}
          whyPodIcon={src}
          gridSize={4}
        />
      ));
    }
    return investorWhyPodData.map(({ title, content, src }, i) => (
      <WhyPodCard
        key={i}
        title={title}
        content={content}
        whyPodIcon={src}
        gridSize={6}
      />
    ));
  };

  return (
    <Box component="section" pt="77px" pb="88px">
      <Container maxWidth="lg">
        <LetterSpacedHeading
          sx={{ pt: '20px', pb: '70px' }}
          component="h2"
          fontSize="30px"
        >
          WHY POD
        </LetterSpacedHeading>

        <Grid container direction="row" spacing={5}>
          {renderCards()}
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyPod;
