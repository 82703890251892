import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/system';

interface BannerTitleProps {
  title1: string;
  title2: string;
  color?: string;
}

export function BannerTitle({
  title1,
  title2,
  color,
  ...typographyProps
}: BannerTitleProps & TypographyProps) {
  return (
    <Typography
      component="h1"
      variant="h2"
      {...typographyProps}
      color={color}
      sx={{
        fontSize: {
          xs: 40,
          md: 64,
        },
        lineHeight: {
          xs: '50px',
          md: '74px',
        },
      }}
    >
      {title1}&nbsp;
      <br />
      {title2}
    </Typography>
  );
}
