import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/system';
import { ReactText } from 'react';
import { styled } from '@mui/system';

const StyledText = styled(Typography)(({ theme: _theme }) => ({
  color: 'rgba(17, 17, 17, 0.7)',
  padding: '19px 0 32px 0',
  fontSize: 20,
  fontWeight: 300,
  lineHeight: '30px',
}));

export default function BannerContent({
  children,
  ...typographyProps
}: { children: ReactText } & TypographyProps) {
  return (
    <StyledText variant="body1" {...typographyProps}>
      {children}
    </StyledText>
  );
}
