import { Typography } from '@mui/material';
import { FC } from 'react';

export const NumbersCount: FC<{ count: string }> = ({
  count,
  ...otherProps
}) => {
  return (
    <Typography variant="h4" {...otherProps}>
      {count}
    </Typography>
  );
};
